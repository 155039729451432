import Router from 'next/router';
import moment from 'moment';
import { getProfileData } from "../../../shared/lib/myProfile";

const commonFunction = {
    isValueEmpty: function (data) {
        // if (typeof data === 'object') {
        //     if (data !== undefined && data !== null && data !== '' && Object.keys(data).length > 0) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // } else {
        //     if (data !== undefined && data !== null && data !== '') {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // }
        if (data !== undefined && data !== null && data !== '') {
            return false;
        } else {
            return true;
        }
    },

    redirectTo: function (url, querys) {
        Router.push({
            pathname: url,
            query: querys,
        })
    },

    getCookieTocken: function (cname) {
        var name = cname + '=';
        var decodedCookie = (typeof window !== "undefined") ? decodeURIComponent(document.cookie) : "";
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },
    specDateEle(date, type, a) {
        let toDefaultFormat = moment(date, this.state.dateFormat);
        let getDate = new Date(toDefaultFormat._d);
        let getYear = typeof date === 'string' ? getDate.getFullYear() : date.getFullYear();
        let getMonth = typeof date === 'string' ? getDate.getMonth() : date.getMonth();
        let getDay = typeof date === 'string' ? getDate.getDate() : date.getDate();
        switch (type) {
            case 'y':
                return getYear
            case 'm':
                return getMonth
            case 'd':
                return getDay
            case 'a':
                return [getYear, getMonth, getDay];
            default:
        }
    },
    repeatString: function (pattern, count) {
        for (var i = 0, r = ''; i < count; i++) { r += pattern; }
        return r;
    },
    getDateWithFormat: function (date, format) {
        let isDate = !this.isValueEmpty(date) ? moment(new Date(date)) : moment();
        if (!this.isValueEmpty(date)) {
            if (!this.isValueEmpty(format)) return isDate.format(format);
            else return isDate.format("DD-MMM-YYYY");
        } else return "NA"
    },
    isNumberRegx: function (data) {
        let regexNum = /^\d*\.?\d*$/.test(data);
        return regexNum;
    },
    isAlphaNumericRegx: function (data) {
        let regexNum = /^[a-zA-Z0-9_]*$/.test(data);
        return regexNum;
    },
    
    isEmailRegx: function (data) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return regex.test(data);
    },
    useCommas: function(price){
        const newPrice = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return newPrice
    },
     currencyAmountRounding:function(num, site_decimal_place) {
        try {
            const decimal_digits = !isNaN(site_decimal_place) ? site_decimal_place : 2;
            if (!isNaN(num)) {
                num = Number(num).toFixed(decimal_digits);
                // num = num.toLocaleString();
            }
        } catch (err) {
            console.log("currencyAmountRounding num =", num);
            console.log("currencyAmountRounding", err);
        }
        return num;
    },
    getMonthsRange: function(range = 12) {
        var now = new Date();
        var month = now.getMonth();
        var year = now.getFullYear();
    
        var names = ['January', 'February', 'March', 'April', 'May', 'June',
                     'July', 'August', 'September', 'October', 'November', 'December'];
    
        var res = [];
        for (var i = 0; i < range; ++i) {
            res.push({month: names[month] + ' ' + year});
            if (++month === 12) {
                month = 0;
                ++year;
            }
        }
        return res;
    },
    getUserProfile: (userToken,site_id) => {
        return new Promise(function (resolve, reject) {
            let data = {
                "accessToken": userToken !== undefined && userToken !== null && userToken !== "" ? userToken : '',
                "site_id": site_id !== undefined && site_id !== null && site_id !== "" ? site_id : '' /*"6124e1368ede4f08bf2d9ad4"*/
            }
            getProfileData(data).then(res => {
                if (res && res?.status && res?.status === true) {
                    if (res?.data && res?.data && res?.data?.data && res?.data?.data?.length > 0) {
                        res.data.data.map(ele => {
                            resolve({
                                status: true, data: {
                                    email: ele.email, registraion_token_status: ele.registraion_token_status,
                                    type: ele.type, user_agent: ele.user_agent, bounz_membership: ele.bounz_membership, bounz_status: ele.bounz_status,
                                    travellers: ele.travellers, corporate_detail:ele.corporate_detail
                                }
                            })
                        })
                    }
                    resolve({ status: false, message: "No data" })
                }
                resolve({ status: false, message: "Something is missing" })
                reject({ status: false, message: "Service Error" })
            }).catch(err => {
                reject({ status: false, error: err })
            })
        });
    },
    capitalizeWords: (str) => {
        let words = str.toLowerCase().split(/\b/);
        for (let i = 0; i < words.length; i++) {
            if (words[i].match(/[a-z]/i)) {
                words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
            }
        }
        return words.join('');
    },
    convertCurrency :(curCode, totalAmt) => {
        const exchangeRates = {
            'AED': 19.57
        };
        if (curCode in exchangeRates) {
            const exchangeRate = exchangeRates[curCode];
            const convertedAmt = totalAmt * exchangeRate;
            return convertedAmt;
        } else {
            return `Exchange rate not found for currency code: ${curCode}`;
        }
    } 
}
export default commonFunction;