import React, { Component, Fragment } from "react";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";
import Link from "../../../Link";

class Footer2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "red",
      emailId: "",
      linksData: [],
    };
  }

  onChangeEmail = (data) => {
    this.setState({
      emailId: data.target.value,
    });
  };
  componentDidMount() {
    if (
      !commonFunction.isValueEmpty(this.props) &&
      !commonFunction.isValueEmpty(this.props.data)
    ) {
      if (
        !commonFunction.isValueEmpty(this.props.data.footermenu) &&
        !commonFunction.isValueEmpty(this.props.data.footermenu.menu)
      ) {
        this.setState({ linksData: this.props?.data?.footermenu?.menu });
      }
    }
  }

  render() {
    let downloadAppWidget = {};
    if (localStorage.getItem("appType") == "mobileapp") {
      downloadAppWidget = { display: "none" };
    }
    return (
      <>
        <footer
          className={this.props?.data?.cssfile?.footer_main}
          id="foootermaim"
        >
          <div
            className={`container px-4 xl:px-8 ${this.props?.data?.cssfile?.footer_contMain}`}
          >
            <div className={this.props?.data?.cssfile?.footer_service_flx}>
              {/* <div className={this.props?.data?.cssfile?.footer_service}>
                      <div className={this.props?.data?.cssfile?.footer_serviceHead}>Our Products</div>
                      <div className={this.props?.data?.cssfile?.footer_serviceList}>
                          <ul>
                              <li>Domestic Hotels</li>
                              <li>International Hotels</li>
                              <li>Domestic Flights</li>
                              <li>International Flights</li>
                              <li>Couple Friendly Hotels</li>
                              <li>Nearby Getaways</li>
                              <li>Cab Booking</li>
                              <li>Airport Cabs Booking</li>
                              <li>Outstation Cabs Booking</li>
                              <li>Go Stays</li>
                              <li>Trip Money</li>
                              <li>Zenith Holidays Advertising Solutions</li>
                          </ul>
                      </div>
                  </div> */}
              {/* <div className={this.props?.data?.cssfile?.footer_service}>
                      <div className={this.props?.data?.cssfile?.footer_serviceHead}>About Us</div>
                      <div className={this.props?.data?.cssfile?.footer_serviceList}>
                          <ul>
                              <li>About Us</li>
                              <li>Investor Relations</li>
                              <li>Management</li>
                              <li>Terms of Services</li>
                              <li>User Agreement</li>
                              <li>Privacy</li>
                              <li>Careers</li>
                              <li>YouTube Channel</li>
                              <li>Technology@zenithholidays.com</li>
                              <li>Customer Support</li>
                              <li>Twitter Handle</li>
                          </ul>


                      </div>
                  </div> */}

              <div className={this.props?.data?.cssfile?.footer_mainCont}>
                {/* <div className={this.props?.data?.cssfile?.footer_serviceHead}>
                  Information{" "}
                </div> */}
                {/* <div className={this.props?.data?.cssfile?.footer_aboutCont}>
                  <div className={this.props?.data?.cssfile?.footer_aboutBox}>
                    <ul>
                    {this.state.linksData?.map((link, i) => {
                      if (i < 10 && link.active === 'active') {
                        console.log('firstblock', i)
                        return (
                          <li>
                          <Link
                            title={link.text}
                            key={link.text}
                            href={link.url}
                            className=""
                          >
                            {link.text}
                          </Link>
                          </li>
                        );
                      }
                    })}
                    </ul>
                  </div>
                  <div className={this.props?.data?.cssfile?.footer_aboutBox}>
                    <ul>
                      
                    { this.state.linksData?.map((link, i) => {
                      if (i >= 10  && link.active === 'active') {
                     
                        return (
                          <li>
                          <Link
                            title={link.text}
                            key={link.text}
                            href={link.url}
                            className=""
                          >
                            {link.text}
                          </Link>
                          </li>
                        );
                      }
                    })}
                    </ul>
                  </div>
                </div> */}
                {/* <div className={this.props?.data?.cssfile?.footer_aboutCont}>
                  {this.state.linksData?.map((link) => {
                    return link.active == "active" ? (
                      <Link
                        title={link.text}
                        key={link.text}
                        href={link.url}
                        className=""
                      >
                        {link.text}
                      </Link>
                    ) : (
                      ""
                    );
                  })}
                </div> */}
              </div>

              <div className={this.props?.data?.cssfile?.footer_socialWp}>
                {/* <div className={this.props?.data?.cssfile?.footer_service}>
                      <div className={this.props?.data?.cssfile?.footer_serviceHead}>Travel Essentials</div>
                      <div className={this.props?.data?.cssfile?.footer_serviceList}>
                          <ul>
                              <li>PNR Status</li>
                              <li>Offers</li>
                              <li>Airline Routes</li>
                              <li>More Links</li>
                              <li>Cheap Flights</li>
                              <li>Hotels Near Me</li>
                              <li>My Bookings</li>
                              <li>Cancellation</li>
                              <li>My Account</li>
                              <li>Advertise with Us</li>
                          </ul>
                      </div>
                  </div> */}

                <div className={this.props?.data?.cssfile?.footer_service}>
                  <div className={this.props?.data?.cssfile?.foot_btmSrv}>
                    <div
                      className={this.props?.data?.cssfile?.footer_serviceHead}
                    >
                      Follow Us
                    </div>
                    <div
                      className={this.props?.data?.cssfile?.footer_send_email}
                    >
                      <div className={this.props?.data?.cssfile?.social_media}>
                        {!commonFunction.isValueEmpty(this.props) &&
                          !commonFunction.isValueEmpty(this.props.data) &&
                          !commonFunction.isValueEmpty(
                            this.props?.data?.footermenu
                          ) &&
                          !commonFunction.isValueEmpty(
                            this.props?.data?.footermenu?.socialmedia
                          ) &&
                          this.props?.data?.footermenu?.socialmedia.map(
                            (sociallink, index) => (
                              <Fragment key={"socialmedia" + index}>
                                {sociallink.active == "active" ? (
                                  <a
                                    title={sociallink.text}
                                    key={sociallink.text}
                                    href={
                                      localStorage.getItem("appType") ==
                                      "mobileapp"
                                        ? "/mobileAppLink?page=" +
                                          sociallink.text +
                                          "&value=" +
                                          sociallink.url
                                        : sociallink.url
                                    }
                                    className=""
                                  >
                                    <img
                                      src={`${
                                        "/shared/api/media/" +
                                        this.props?.data?.siteData?.site.id +
                                        "/" +
                                        sociallink?.icon
                                      }`}
                                      alt={sociallink.text}
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </Fragment>
                            )
                          )}
                      </div>
                    </div>
                  </div>

                  {/* <div className={this.props?.data?.cssfile?.footer_logo}>
                    <span onClick={() => {
                                        window.location.href = "/";
                                      }}>
                <input
                  type="image"
                  src={`${
                    "/shared/api/media/" +
                    this.props?.data?.siteData?.site.id +
                    "/" +
                    this.props?.data?.footermenu?.logo
                  }`}
                  alt="Logo"
                />
                </span>
              </div> */}

                  {/* <div className={this.props?.data?.cssfile?.foot_btmSrv}>
                      <div className={this.props?.data?.cssfile?.footer_serviceHead}>Download App</div>
                        <div className={this.props?.data?.cssfile?.footer_downCont}>
                          <div className={this.props?.data?.cssfile?.download_platform}>
                           <div className={this.props?.data?.cssfile?.downd_icon}>
                            <img src="/theme5/g-play.png" alt="google-play" />
                           </div>
                           <div className={this.props?.data?.cssfile?.downd_icon}>
                            <img src="theme5/app-store.png" alt="app-store" />
                           </div>
                          </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className={this.props?.data?.cssfile?.footer_service}>
                  <div className={this.props?.data?.cssfile?.foot_btmSrv}>
                    <div
                      className={this.props?.data?.cssfile?.footer_serviceHead}
                    >
                      Card{" "}
                    </div>
                    <div
                      className={this.props?.data?.cssfile?.footer_send_email}
                    >
                      <div
                        className={`${this.props?.data?.cssfile?.card_icon}`}
                      >
                        {!commonFunction.isValueEmpty(this.props) &&
                          !commonFunction.isValueEmpty(this.props.data) &&
                          !commonFunction.isValueEmpty(
                            this.props?.data?.footermenu
                          ) &&
                          !commonFunction.isValueEmpty(
                            this.props?.data?.footermenu?.cardItem
                          ) &&
                          this.props?.data?.footermenu?.cardItem.map(
                            (carditem, index) => (
                              <Fragment key={"cardItem" + index}>
                                {true ? (
                                  <Link
                                    title={carditem.text}
                                    key={carditem.text}
                                    // href={carditem.url}
                                    className=""
                                  >
                                    <img
                                      src={`${
                                        "/shared/api/media/" +
                                        this.props?.data?.siteData?.site.id +
                                        "/" +
                                        carditem?.icon
                                      }`}
                                      alt={carditem.text}
                                    />
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </Fragment>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* <div className="flex flex-row justify-between container items-start mx-auto xl:px-8">
            <div className={this.props?.data?.cssfile?.footer_logo}>
              <input
                type="image"
                src={`${
                 
                  "/shared/api/media/" +
                  this.props?.data?.siteData?.site.id +
                  "/" +
                  this.props?.data?.footermenu?.logo
                }`}
                alt="Logo"
              />
            </div>
            <div className={this.props?.data?.cssfile?.footer_links}>
              {this.state.linksData?.map((link) => {
                return link.active == "active" ? (
                  <Link
                    title={link.text}
                    key={link.text}
                    href={link.url}
                    className=""
                  >
                    {link.text}
                  </Link>
                ) : (
                  ""
                );
              })}
            </div>
            <div className={this.props?.data?.cssfile?.footer_send_email}>
             
              <div className={this.props?.data?.cssfile?.social_media}>
                {!commonFunction.isValueEmpty(this.props) &&
                  !commonFunction.isValueEmpty(this.props.data) &&
                  !commonFunction.isValueEmpty(this.props?.data?.footermenu) &&
                  !commonFunction.isValueEmpty(
                    this.props?.data?.footermenu?.socialmedia
                  ) &&
                  this.props?.data?.footermenu?.socialmedia.map(
                    (sociallink, index) => (
                      <Fragment key={"socialmedia" + index}>
                        {sociallink.active == "active" ? (
                          <Link
                            title={sociallink.text}
                            key={sociallink.text}
                            href={sociallink.url}
                            className=""
                          >
                           
                            <img
                              src={`${
                               
                                "/shared/api/media/" +
                                this.props?.data?.siteData?.site.id +
                                "/" +
                                sociallink?.icon
                              }`}
                              alt={sociallink.text}
                            />
                          </Link>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    )
                  )}
              </div>

        
            </div>
          </div> */}

          {/* <div className="flex container xl:px-8 pt-4">
            <div className={this.props?.data?.cssfile?.wp_wrapper}>
              <ul className={this.props?.data?.cssfile?.menu_footer_new}>
                {this.props?.data?.footermenu?.policy?.map((link, index) => (
                  <>
                    {link.active == "active" ? (
                      <li>
                        <Link
                          title={link.text}
                          href={link.url}
                          key={index}
                        >
                          {link.text}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                ))}

             
              </ul>

              <div className={this.props?.data?.cssfile?.wpb_wrap_contact}>
                <a
                  href={
                    "mailto:" + this.props?.data?.siteData?.site?.support_email
                  }
                  target="_blank"
                  title="Support Email"
                >
                  <div className={this.props?.data?.cssfile?.ax_head_menu}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"></path>
                    </svg>
                    {this.props?.data?.siteData?.site?.support_email}
                  </div>
                </a>
                <a
                  href={
                    "tel:" + this.props?.data?.siteData?.site?.support_number
                  }
                  title="Support Number"
                >
                  <div className={this.props?.data?.cssfile?.ax_head_menu}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-telephone"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>{" "}
                    </svg>
                    {this.props?.data?.siteData?.site?.support_number}
                  </div>
                </a>
              </div>
            </div>
          </div> */}
          <div className={`${this.props?.data?.cssfile?.travel_copyright}`}>
            {/* Download section  */}
            <div
              className={`${this.props?.data?.cssfile?.download_container}
            ${this.props?.data?.cssfile?.download_desktopHide} 
            ${this.props?.data?.cssfile?.download_mobileShow}
            `}
              style={downloadAppWidget}
            >
              <div className={this.props?.data?.cssfile?.download_Title}>
                Download App
              </div>
              <div className={this.props?.data?.cssfile?.download_main_wrap}>
                <div className={this.props?.data?.cssfile?.download_App_wp}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.zenith.holidays"
                    target="blank"
                  >
                    <img src="/theme5/g-play.png" alt="google-play" />
                  </a>
                </div>
                <div className={this.props?.data?.cssfile?.download_App_wp}>
                  <a
                    href="https://apps.apple.com/in/app/zenith-holidays/id6476206987"
                    target="blank"
                  >
                    <img src="/theme5/app-store.png" alt="App-store" />
                  </a>
                </div>
              </div>
            </div>
            {/* Download section  */}

            <div
              className={`container px-4 xl:px-8 ${this.props?.data?.cssfile?.trav_copyFlx}`}
            >
              <span>{this.props?.data?.footermenu?.text}</span>
              <div className={this.props?.data?.cssfile?.trav_copyIcon}>
                {/* <img src="theme5/brand-cc.png" alt="brand_icon" /> */}
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer2;
