import FaceBookLoginComponent from './FacebookLoginComponent.js';
import GoogleLoginComponent from './GoogleLoginComponent.js';
import ReactFacebookLogin from 'react-facebook-login';
import config from '../../../../../config/config.json'
import { facebooklogin } from '../../../../lib/facebooklogin.js';
// import styles from "./index.module.css"

const FacebookGoogleLogin = (props) => {
    // console.log(props,"facebook");
    return (
        <div className={props?.styles?.Social_login}>
            {/* {props.siteData.site.facebook_login_key == "" && <FaceBookLoginComponent btnText={ "Login with Facebook"} imageSrc='images/facebook.svg' siteData={props.siteData.site} />} */}
            {/* {true && <FaceBookLoginComponent btnText={""} imageSrc='theme5/facebook.png' styles={props.styles} siteData={props.siteData.site} />} */}
            {props.siteData.site.google_login_key !== "" && <GoogleLoginComponent isSignUp={props.isSignUp} getSignUpForm={props.getSignUpForm} text={'Login'}  siteData={props.siteData.site} theme={props.theme} styles={props.styles} isSignIn={props.isSignIn} />}
            
        </div>
    )
}
export default FacebookGoogleLogin;