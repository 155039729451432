import React, { Component } from "react";
import {
  authenticatedUser,
  getCookieTocken,
  removeUserTokenFromCookie,
  setTockenInCoockie,
} from "../../login/userToken";

import Analytics from "../../../../common/analytics";
import FBPixCodeScript from "../../../../common/analytics/FacebookPixelCode.js";
import Link from "../../../Link";
import UploadLogo from "store/uploadLogo";
import chat from "../../../lib/chat";
import { checksession } from "../../../lib/checksession";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";
import { logout } from "../../../lib/logout";

// import styles from "./index.module.css"

class Header1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPopup: false,
      userFirststname: "",
      userType: "",
      openInitialPopup: false,
      onHoverSupport: false,
      openInitialPopupContact: false,
      activeLink: "",
      activeUrl: "",
    };
  }
  componentDidMount() {
    if (
      window.location.pathname == "/" ||
      window.location.pathname.includes("flight")
    ) {
      this.setState({ activeLink: "Flight" });
    }
    if (
      window.location.pathname == "/hotels" ||
      window.location.pathname.includes("hotel")
    ) {
      this.setState({ activeLink: "Hotels" });
    }
    // console.log(this.props,"header");
    //check if varification token is there
    document.addEventListener("mousedown", this.handleCalendarClickOutside);
    // checksession().then(response => {
    //     console.log("response---------", response)
    //     if (response.status) {
    //         this.setState({
    //             userFirststname: response?.first_name ? response?.first_name.charAt(0) : response?.email.charAt(0),
    //             userType: response.type
    //         })
    //     } else {
    //         this.setState({
    //             userFirststname: "",
    //             userType: ""
    //         })
    //     }
    // })

    // crisp chat
    // window.$crisp = [];
    // window.CRISP_WEBSITE_ID = "f2897580-9f38-4425-93fa-079d3932625f";
    // (() => {
    // const d = document;
    // const s = d.createElement("script");
    // s.src = "https://client.crisp.chat/l.js";
    // s.async = 1;
    // d.getElementsByTagName("body")[0].appendChild(s);
    // })();
    //
    chat(this.props?.data?.siteData?.site?.chat, this.props?.data?.isMobile);

    if (this.props?.data?.user?.status) {
      this.setState({
        userFirststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.first_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        userType: this.props?.data?.user?.type,
      });
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
    // this.checkSessionApi()
  }

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleCalendarClickOutside);
  };

  setCalendarWrapperRef = (node) => {
    this.calendarWrapperRef = node;
  };

  handleCalendarClickOutside = (event) => {
    if (
      !this.calendarWrapperRef ||
      !this.calendarWrapperRef.contains(event.target)
    ) {
      this.setState({ openInitialPopup: false });
      this.setState({ openInitialPopupContact: false });
    }
  };

  checkSessionApi = () => {
    if (
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      this?.props?.data?.sessionData?.status === true
    ) {
      this.setState(
        {
          userFirststname:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.email
            ) &&
            (!commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.first_name
            )
              ? this?.props?.data?.sessionData?.first_name.charAt(0)
              : this?.props?.data?.sessionData?.email.charAt(0)),
          userType:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.type
            ) && this?.props?.data?.sessionData?.type,
        },
        () => {}
      );
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
  };
  onClickArrow = () => {
    this.setState({
      isShowPopup: !this.state.isShowPopup,
    });
  };
  onMouseLeave = () => {
    this.setState({
      isShowPopup: false,
    });
  };
  // componentWillReceiveProps(state, props) {
  //     this.checkSessionApi()
  // }
  onClickProfile = () => {
    // commonFunction.redirectTo('/myprofile')
    window.location.href = "/myprofile";
  };
  onClickLogout = () => {
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status === true ||
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            userFirststname: "",
            userType: "",
          });
          // location.reload()

          if (this.props?.data?.siteData?.site?.business_b2b) {
            window.location.pathname != "/login"
              ? (window.location.href = "/login")
              : null;
          } else {
            // commonFunction.redirectTo('/')
            window.location.href = "/";
          }
        } else {
        }
      })
      .catch((err) => {});
  };

  myBookingClickHandler = () => {
    // commonFunction.redirectTo('/myprofile', { Linkindex: 1 })
    window.location.href = `/myprofile?Linkindex=1`;
  };

  setActiveLink = (id) => {
    this.setState({ activeLink: id });
  };

  render() {
    // console.log('demo',this.props.data.user.agent_setting.logo)
    return (
      <header className="" ref={this.setFamilyWrapperRef}>
        {/* <FBPixCodeScript /> */}
        <Analytics {...this.props?.data?.siteData?.site?.analytics_module} />
        <div className={this.props.data.cssfile.header_section}>
          <div className="flex flex-row justify-between items-center container mx-auto px-4 xl:px-8">
            <h1 className={this.props.data.cssfile.header_main_wd}>
              <Link
                key={""}
                href={"/"}
                // onclick="window.location.href='/'"
                //onClick={() => { window.location.href = this.props.data.headermenu.supportlink }}
                className=""
              >
                <img
                  className={this.props?.data?.cssfile?.header_logo}
                  src={`${
                    "/shared/api/media/" +
                    (UploadLogo?._currentValue?.isLogo
                      ? this.props?.data?.headermenu?.logo
                      : this.props?.data?.headermenu?.logo)
                  }`}
                  alt="logo"
                />
                {/* <img className={this.props?.data?.cssfile?.header_logo} src={`${ '/shared/api/media/' + this.props.data.headermenu.logo}`} alt="logo" /> */}
              </Link>
            </h1>
            <div className={this.props.data.cssfile.header_section_cont}>
              <h2>
                <div className={this.props.data.cssfile.header_all_section}>
                  {!commonFunction.isValueEmpty(this.props) &&
                    !commonFunction.isValueEmpty(this.props.data) &&
                    !commonFunction.isValueEmpty(this.props.data.headermenu) &&
                    !commonFunction.isValueEmpty(
                      this.props.data.headermenu.menu
                    ) &&
                    this.props.data.headermenu.menu.map(
                      (link, i) =>
                        link?.status.toLowerCase() === "active" && (
                          <div
                            key={"headerMenu" + i}
                            className={`${
                              this.props.data.cssfile.header_sections
                            } ${
                              this.state.activeLink === link.text
                                ? this.props.data.cssfile.active
                                : ""
                            }`}
                            onClick={() => this.setActiveLink(link.text)}
                          >
                            {link.text == "Flight" || link.text == "Hotels" ? (
                              <Link
                                key={link.text}
                                href={link.url}
                                //onClick={() => { window.location.href = link.url }}
                                // onClick={this.setActiveLink(i)}
                                // className={this.state.activeLink === i ? 'active' : ''}
                              >
                                {link.text}
                                {/* <img
                                  src={`${
                                    
                                    "/shared/api/media/" +
                                    this.props?.data?.siteData?.site.id +
                                    "/" +
                                    link.icon
                                  }`}
                                  alt=""
                                /> */}
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                        )
                    )}
                </div>
              </h2>
            </div>
            {this.state.userType === "B2B" ? (
              <div className={this.props.data.cssfile.cmpny_agnt_nme}>
                <div className={this.props.data.cssfile.cmpny_nme_wp}>
                  <span className={this.props.data.cssfile.cmpny_title}>
                    Company Name:{" "}
                  </span>
                  <span
                    className={this.props.data.cssfile.cmpny_name}
                    title={this.props?.data?.user?.company_name}
                  >
                    {" "}
                    {this.props?.data?.user?.company_name}
                  </span>
                </div>
                <div className={this.props.data.cssfile.cmpny_nme_wp}>
                  <span className={this.props.data.cssfile.cmpny_title}>
                    Available Limit: AED{" "}
                  </span>
                  <span
                    style={{ paddingLeft: 5 }}
                    className={this.props.data.cssfile.cmpny_prce_limit}
                  >
                    {Intl.NumberFormat("en-US").format(
                      this.props?.data?.user?.agent_balance?.Booking_Balance
                    )}
                  </span>
                </div>
              </div>
            ) : null}
            <div
              className={this.props.data.cssfile.afterlogin}
              ref={this.setCalendarWrapperRef}
            >
              {this.state.userFirststname != undefined &&
              this.state.userFirststname != null &&
              this.state.userFirststname != "" ? (
                <>
                  <div className={this.props.data.cssfile.currency_cont}>
                    <div className={this.props.data.cssfile.crncy_flx}>
                      <img src="" alt="" />
                      <span className={this.props.data.cssfile.crncy_label}>
                        BHD
                      </span>
                      {/* <span class={this.props.data.cssfile.down_arrow}></span> */}
                      {/* <svg width="20px" height="24px" viewBox="0 0 200 200"><polygon points="180.8,40.25 19.2,40.25 100.202,166.5 "></polygon></svg> */}
                    </div>
                  </div>
                  <div className={this.props.data.cssfile.help_cont}>
                    <div
                      className={this.props.data.cssfile.help_flx}
                      onClick={() => {
                        this.setState({ openInitialPopupContact: true });
                        this.setState({ openInitialPopup: false });
                      }}
                    >
                      <span className={this.props.data.cssfile.hlp_label}>
                        Help
                      </span>
                      {/* <span class={this.props.data.cssfile.down_arrow}></span> */}
                      <svg width="15px" height="24px" viewBox="0 0 200 200">
                        <polygon points="180.8,40.25 19.2,40.25 100.202,166.5 "></polygon>
                      </svg>
                    </div>
                  </div>
                  <div className={this.props.data.cssfile.afterlogin}>
                    {" "}
                    <span className={this.props.data.cssfile.user_code}>
                      {this.state.userFirststname}
                    </span>
                    <span
                      onClick={() => {
                        this.onClickArrow();
                      }}
                    >
                      <img src="/images/down_arrow.jpg" alt="" />
                    </span>
                    <div
                      className={
                        this.state.isShowPopup
                          ? this.props.data.cssfile.show
                          : this.props.data.cssfile.profile_details_hide
                      }
                      onMouseLeave={() => {
                        this.onMouseLeave();
                      }}
                    >
                      {/* <div className={styles.profile_frst}> */}
                      <div className={this.props.data.cssfile.profile_frst}>
                        <span
                          onClick={() => {
                            this.onClickProfile();
                          }}
                        >
                          {" "}
                          <div className={this.props.data.cssfile.menu_icon}>
                            <img
                              type="image"
                              src="/images/profile-01.svg"
                              alt="profile"
                            />
                          </div>
                          My Profile
                        </span>
                        <span onClick={this.myBookingClickHandler}>
                          {" "}
                          <div className={this.props.data.cssfile.menu_icon}>
                            <img
                              type="image"
                              src="/images/booking-01.svg"
                              alt="mybooking"
                            />
                          </div>
                          My Booking
                        </span>
                        <span
                          onClick={() => {
                            this.onClickLogout();
                          }}
                        >
                          {" "}
                          <div className={this.props.data.cssfile.menu_icon}>
                            <img
                              type="image"
                              src="/images/logout-01.svg"
                              alt="Logout"
                            />
                          </div>
                          Logout
                        </span>
                      </div>
                      {/* <div className={`${styles.profile_frst} ${styles.support_scnd}`}> */}
                      {/* <div
                      className={`${this.props.data.cssfile.profile_frst} ${this.props.data.cssfile.support_scnd}`}
                    >
                      <div class={this.props.data.cssfile.support_heading}>
                        Support
                      </div>
                      <a
                        href={`tel:${this.props?.data?.siteData?.site?.support_number}`}
                      >
                        <span>
                          {" "}
                          <div className={this.props.data.cssfile.menu_icon}>
                            <img
                              type="image"
                              src="/images/call-01.svg"
                              alt="Call"
                            />
                          </div>
                          {this.props?.data?.siteData?.site?.support_number}{" "}
                        </span>
                      </a>
                      <a
                        href={`mailto:${this.props?.data?.siteData?.site?.support_email}`}
                      >
                        <span>
                          <div className={this.props.data.cssfile.menu_icon}>
                            <img
                              type="image"
                              src="/images/email-01.svg"
                              alt="Support"
                            />
                          </div>
                          {this.props?.data?.siteData?.site?.support_email}
                        </span>
                      </a>

                      <a href="tel:+971 58 953 9988">
                                                <span> <div className={this.props.data.cssfile.menu_icon}><img type="image" src="/images/call-01 (1).svg" alt="" /></div>+971 58 953 9988</span>
                                            </a>
                                            <a href="mailto:connect@elevatetrips.com"><span><div className={this.props.data.cssfile.menu_icon}><img type="image" src="/images/email-01.svg" alt="" /></div>connect@elevatetrips.com</span></a>
                    </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={this.props.data.cssfile.currency_cont}>
                    <div className={this.props.data.cssfile.crncy_flx}>
                      <img src="" alt="" />
                      <span className={this.props.data.cssfile.crncy_label}>
                        BHD
                      </span>
                      {/* <span class={this.props.data.cssfile.down_arrow}></span> */}
                      {/* <svg width="20px" height="24px" viewBox="0 0 200 200"><polygon points="180.8,40.25 19.2,40.25 100.202,166.5 "></polygon></svg> */}
                    </div>
                  </div>
                  <div className={this.props.data.cssfile.help_cont}>
                    <div
                      className={this.props.data.cssfile.help_flx}
                      onClick={() => {
                        this.setState({ openInitialPopupContact: true });
                        this.setState({ openInitialPopup: false });
                      }}
                    >
                      <span className={this.props.data.cssfile.hlp_label}>
                        Help
                      </span>
                      {/* <span class={this.props.data.cssfile.down_arrow}></span> */}
                      <svg width="15px" height="24px" viewBox="0 0 200 200">
                        <polygon points="180.8,40.25 19.2,40.25 100.202,166.5 "></polygon>
                      </svg>
                    </div>
                  </div>
                  <div
                    className={this.props.data.cssfile.login_btn}
                    // onClick={this.props.data.onClickHeaderLogin}
                    onClick={() => {
                      this.setState({ openInitialPopup: true });
                      this.setState({ openInitialPopupContact: false });
                    }}
                  >
                    <svg
                      className={this.props.data.cssfile.user_icon}
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      id="user-circle"
                    >
                      <path
                        fill="#A2A2A2"
                        d="M12,2A10,10,0,0,0,4.65,18.76h0a10,10,0,0,0,14.7,0h0A10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-5.55-2.25,6,6,0,0,1,11.1,0A8,8,0,0,1,12,20ZM10,10a2,2,0,1,1,2,2A2,2,0,0,1,10,10Zm8.91,6A8,8,0,0,0,15,12.62a4,4,0,1,0-6,0A8,8,0,0,0,5.09,16,7.92,7.92,0,0,1,4,12a8,8,0,0,1,16,0A7.92,7.92,0,0,1,18.91,16Z"
                      ></path>
                    </svg>
                    <button>Login </button>
                    {/* <span class={this.props.data.cssfile.down_arrow}></span> */}
                    <svg width="15px" height="24px" viewBox="0 0 200 200">
                      <polygon points="180.8,40.25 19.2,40.25 100.202,166.5 "></polygon>
                    </svg>
                    {/* <img
                    type="image"
                    src="/images/imgpsh_fullsize_anim.png"
                    alt="Sign Up"
                  /> */}
                  </div>
                </>
              )}

              {this.state.openInitialPopup && (
                <div
                  className="logindrop logindrp drpLogin"
                  id="loginDrop"
                  style={{ display: "block" }}
                >
                  <div
                    className="ax-head-menu"
                    style={{ marginBottom: "0px" }}
                    onClick={() => {
                      this.props.data.onClickHeaderLogin("LogIn");
                    }}
                  >
                    <img type="image" src="/images/loginew.svg" alt="Login" />
                    Login
                  </div>
                  <div
                    className="ax-head-menu"
                    style={{ marginBottom: "0px" }}
                    onClick={() => {
                      this.props.data.onClickHeaderLogin("BeforeSignUp");
                    }}
                  >
                    <img
                      type="image"
                      src="/images/registere.svg"
                      alt="Sign Up"
                    />
                    Sign Up
                  </div>
                  <div
                    className="ax-head-menu etsupport etsupport-desktop"
                    id="etsupport"
                    onMouseEnter={() => {
                      this.setState({ onHoverSupport: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ onHoverSupport: false });
                    }}
                  >
                    <span className="support-txt">
                      <img
                        type="image"
                        id="supporticon"
                        className="supp"
                        src="/images/profile-01.svg"
                        alt="Support"
                        width="24px"
                        height="24px"
                      />
                      Support
                    </span>
                    {this.state.onHoverSupport && (
                      <div className="supportdrop" style={{ display: "block" }}>
                        <a href="tel:+971 58 953 9988">
                          <div
                            className="ax-head-menu"
                            style={{ marginBottom: "0px" }}
                          >
                            {/* <img src="/images/et-hd-phn.png" alt="" /> */}
                            {/* +971 58 953 9988 */}
                            <svg
                              style={{ display: "inline", marginRight: "10" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-telephone"
                              viewBox="0 0 16 16"
                            >
                              {" "}
                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>{" "}
                            </svg>
                            {this.props?.data?.siteData?.site?.support_number}
                          </div>
                        </a>
                        <a href="#">
                          <div
                            className="ax-head-menu"
                            style={{ marginBottom: "0px" }}
                          >
                            {/* <img
                              src="/images/et-hd-email.png"
                              alt=""
                              width="20px"
                              height="20px"
                            /> */}
                            {/* connect@elevatetrips.com */}
                            <svg
                              style={{ display: "inline", marginRight: "10" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"></path>
                            </svg>
                            {this.props?.data?.siteData?.site?.support_email}
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.openInitialPopupContact && (
                <div
                  className="logindrop logindrp drpHelp"
                  id="loginDrop"
                  style={{ display: "block" }}
                >
                  <div
                    className="ax-head-menu"
                    style={{ marginBottom: "0px" }}
                    // onClick={() => {
                    //   this.props.data.onClickHeaderLogin("LogIn");
                    // }}
                  >
                    <svg
                      style={{ display: "inline", marginRight: "10" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-telephone"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>{" "}
                    </svg>
                    {/* <img type="image" src="/images/et-hd-phn.png" alt="Login" /> */}
                    {this.props?.data?.siteData?.site?.support_number}
                  </div>
                  <div
                    className="ax-head-menu"
                    style={{ marginBottom: "0px" }}
                    // onClick={() => {
                    //   this.props.data.onClickHeaderLogin("BeforeSignUp");
                    // }}
                  >
                    <svg
                      style={{ display: "inline", marginRight: "10" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"></path>
                    </svg>
                    {/* <img
                      type="image"
                      src="/images/et-hd-email.png"
                      alt="Sign Up"
                    /> */}
                    {this.props?.data?.siteData?.site?.support_email}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header1;
