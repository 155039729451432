import GA from "./GoogleAnalytics";
import Plausible from "./Plausible";
import SimpleAnalytics from "./SimpleAnalytics";
import Umami from "./Umami";
import siteMetadata from "@/data/siteMetadata";
import FBPixCodeScript from "./FacebookPixelCode";
import GACScript from "./GoogleAnalyticsCode";
import GTagScript from "./GoogleTagManagerCode";
//import Site24x7AnalyticsCode from "./Site24x7AnalyticsCode";

const isProduction = process.env.NODE_ENV === "production";

const Analytics = (props) => {
  // console.log('Analytics',props)
  return (
    <>
      {isProduction && siteMetadata.analytics.plausibleDataDomain && (
        <Plausible />
      )}
      {isProduction && siteMetadata.analytics.simpleAnalytics && (
        <SimpleAnalytics />
      )}
      {isProduction && siteMetadata.analytics.umamiWebsiteId && <Umami />}
      {isProduction && siteMetadata.analytics.googleAnalyticsId && <GA />}
      <GTagScript {...props} />
      <GACScript {...props} />
      <FBPixCodeScript {...props} />
      {/* <Site24x7AnalyticsCode {...props} /> */}
    </>
  );
};

export default Analytics;
