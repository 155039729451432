import React, { Component } from "react";
import Link from "../../../Link";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";
import { logout } from "../../../lib/logout";
import { checksession } from "../../../lib/checksession";
import chat from "../../../lib/chat";
import FBPixCodeScript from "../../../../common/analytics/FacebookPixelCode.js";
import Analytics from "../../../../common/analytics";
import UploadLogo from "store/uploadLogo";

class Header2 extends Component {
  constructor() {
    super();
    this.state = {
      isShowPopup: false,
      userFirststname: "",
      userType: "",
      openInitialPopup: false,
      onHoverSupport: false,
      transform: null,
      scrolling: false,
    };
    this.style = {
      color: "red",
    };
  }

  componentDidMount() {
    //check if varification token is there
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("mousedown", this.handleCalendarClickOutside);
    chat(this.props?.data?.siteData?.site?.chat, this.props?.data?.isMobile);

    if (this.props?.data?.user?.status) {
      this.setState({
        userFirststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.first_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        userType: this.props?.data?.user?.type,
      });
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
    // this.checkSessionApi()
  }

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleCalendarClickOutside);
    window.removeEventListener("scroll", this.handleScroll);
  };

  setCalendarWrapperRef = (node) => {
    this.calendarWrapperRef = node;
  };

  handleScroll = (event) => {
    console.log("clicked");
    if (window.scrollY <= 250 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
      console.log("false");
    } else if (window.scrollY > 250 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
      console.log("true");
    }
  };

  handleCalendarClickOutside = (event) => {
    if (
      !this.calendarWrapperRef ||
      !this.calendarWrapperRef.contains(event.target)
    ) {
      this.setState({ openInitialPopup: false });
    }
  };

  checkSessionApi = () => {
    if (
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      this?.props?.data?.sessionData?.status === true
    ) {
      this.setState(
        {
          userFirststname:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.email
            ) &&
            (!commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.first_name
            )
              ? this?.props?.data?.sessionData?.first_name.charAt(0)
              : this?.props?.data?.sessionData?.email.charAt(0)),
          userType:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.type
            ) && this?.props?.data?.sessionData?.type,
        },
        () => {}
      );
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
  };

  onClickArrow = () => {
    this.setState({
      isShowPopup: !this.state.isShowPopup,
    });
  };

  onMouseLeave = () => {
    this.setState({
      isShowPopup: false,
    });
  };

  // componentWillReceiveProps(state, props) {
  //     this.checkSessionApi()
  // }

  onClickProfile = () => {
    // commonFunction.redirectTo('/myprofile')
    window.location.href = "/myprofile";
  };

  onClickLogout = () => {
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status === true ||
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            userFirststname: "",
            userType: "",
          });
          // location.reload()

          if (this.props?.data?.siteData?.site?.business_b2b) {
            window.location.pathname != "/login"
              ? (window.location.href = "/login")
              : null;
          } else {
            commonFunction.redirectTo("/");
          }
        } else {
        }
      })
      .catch((err) => {});
  };

  myBookingClickHandler = () => {
    // commonFunction.redirectTo('/myprofile', { Linkindex: 1 })
    window.location.href = `/myprofile?Linkindex=1`;
  };

  render() {
    const signupStyle = {
      border: "1px solid #3479c5",
      margin: 12,
      borderRadius: 10,
    };

    const loginStyle = {
      border: "1px solid #3479c5",
      backgroundColor: "var(--buttons_color)",
      margin: 12,
      borderRadius: 10,
    };

    const activeTabStyle = {
      backgroundColor: "#cc0000",
    };

    return (
      <>
        {/* Header for HomePage starts */}
        {/* <FBPixCodeScript /> */}
        <Analytics {...this.props?.data?.siteData?.site?.analytics_module} />

        {window.location.pathname == "/" && (
          <header
            className={`${this.props.data.cssfile.new_header_wrap}  ${
              this.state.scrolling ? this.props.data.cssfile.header_sticky : ""
            }`}
          >
            <div className={this.props.data.cssfile.header_section}>
              <div className="flex flex-row justify-between items-center container mx-auto px-4 xl:px-8">
                <h1>
                  <Link
                    title="Logo"
                    key={"logo"}
                    href={"/"}
                    // onclick="window.location.href='/'"
                    //onClick={() => { window.location.href = this.props.data.headermenu.supportlink }}
                    className=""
                  >
                    <img
                      className={this.props?.data?.cssfile?.header_logo}
                      src={`${
                        "/shared/api/media/" +
                        (UploadLogo?._currentValue?.isLogo
                          ? this.props?.data?.headermenu?.logo
                          : this.props?.data?.headermenu?.logo)
                      }`}
                      alt="logo"
                    />
                  </Link>
                </h1>

                <h2>
                  <div>
                    <div className={this.props.data.cssfile.header_all_section}>
                      {!commonFunction.isValueEmpty(this.props) &&
                        !commonFunction.isValueEmpty(this.props.data) &&
                        !commonFunction.isValueEmpty(
                          this.props.data.headermenu
                        ) &&
                        !commonFunction.isValueEmpty(
                          this.props.data.headermenu.menu
                        ) &&
                        this.props.data.headermenu.menu.map(
                          (link, i) =>
                            link?.status.toLowerCase() === "active" && (
                              <div
                                key={"headerMenu" + i}
                                className={
                                  this.props.data.cssfile.header_sections
                                }
                              >
                                {this.state.scrolling &&
                                link.text != "Offline" ? (
                                  <Link
                                    title={link.text}
                                    key={link.text}
                                    href={link.url}
                                    //onClick={() => { window.location.href = link.url }}
                                    className=""
                                  >
                                    {link.text}
                                    <img
                                      src={`${
                                        "/shared/api/media/" +
                                        this.props?.data?.siteData?.site.id +
                                        "/" +
                                        link.icon
                                      }`}
                                      alt={link.text}
                                    />
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            )
                        )}
                    </div>
                  </div>
                </h2>

                <div className={this.props.data.cssfile.login_container}>
                  <ul className={this.props.data.cssfile.login_contact_dtail}>
                    <li className={this.props.data.cssfile.contact_number}>
                      <a
                        href={
                          "tel:" +
                          this.props?.data?.siteData?.site?.support_number
                        }
                        title="Support Number"
                      >
                        <img src="/images/cu-01.svg" alt="Call-Icon" />
                        <span>
                          {this.props?.data?.siteData?.site?.support_number}
                        </span>
                      </a>
                    </li>
                    {/* <li className={this.props.data.cssfile.contact_number}> */}
                    {/* <a href=""> 
                      <img src="/images/wa-01.svg" alt="Whatsapp-Icon" /> 
                      <span>{this.props?.data?.siteData?.site?.support_number}</span> 
                      </a>  */}
                    {/* </li> */}
                    <li className={this.props.data.cssfile.contact_title}>
                      <span>CONTACT US</span>
                    </li>
                  </ul>

                  <ul className={this.props.data.cssfile.navbar_right}>
                    <li className={this.props.data.cssfile.dropdown}>
                      <span className={this.props.data.cssfile.dropdown_span}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 60 30"
                          width="1200"
                          height="600"
                        >
                          <clipPath id="s">
                            <path d="M0,0 v30 h60 v-30 z" />
                          </clipPath>
                          <clipPath id="t">
                            <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
                          </clipPath>
                          <g clipPath="url(#s)">
                            <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
                            <path
                              d="M0,0 L60,30 M60,0 L0,30"
                              stroke="#fff"
                              strokeWidth="6"
                            />
                            <path
                              d="M0,0 L60,30 M60,0 L0,30"
                              clipPath="url(#t)"
                              stroke="#C8102E"
                              strokeWidth="4"
                            />
                            <path
                              d="M30,0 v30 M0,15 h60"
                              stroke="#fff"
                              strokeWidth="10"
                            />
                            <path
                              d="M30,0 v30 M0,15 h60"
                              stroke="#C8102E"
                              strokeWidth="6"
                            />
                          </g>
                        </svg>
                        <span>EN</span>
                        <span className={this.props.data.cssfile.divider_line}>
                          |
                        </span>
                        <span>AED</span>
                        {/* <img src="/theme2/down_arrow.png" className={this.props.data.cssfile.drop_arrow} alt=""/> */}
                      </span>

                      {/* Language currency dropdown */}
                      <div
                        className={this.props.data.cssfile.dropdown_menu_wrap}
                        style={{ display: "none" }}
                      >
                        <div className={this.props.data.cssfile.language_drop}>
                          <div
                            className={this.props.data.cssfile.language_label}
                          >
                            Language
                          </div>
                          <span
                            className={
                              this.props.data.cssfile.nation_title_wrap
                            }
                          >
                            <img
                              src=""
                              className={this.props.data.cssfile.nation_flag}
                              alt="nation flag"
                            />
                            <span
                              className={this.props.data.cssfile.nation_label}
                            >
                              India
                            </span>
                          </span>

                          <div
                            className={
                              this.props.data.cssfile.country_drop_container
                            }
                            style={{ display: "none" }}
                          >
                            <div
                              className={
                                this.props.data.cssfile.country_scroll_box
                              }
                            >
                              <div
                                className={
                                  this.props.data.cssfile.country_dtail_wrap
                                }
                              >
                                <ul>
                                  <li
                                    className={
                                      this.props.data.cssfile.country_name
                                    }
                                  >
                                    India
                                  </li>
                                  <li
                                    className={
                                      this.props.data.cssfile.country_name
                                    }
                                  >
                                    UAE
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={this.props.data.cssfile.language_drop}>
                          <div
                            className={this.props.data.cssfile.language_label}
                          >
                            Currency
                          </div>
                          <span
                            className={
                              this.props.data.cssfile.nation_title_wrap
                            }
                          >
                            <img
                              src=""
                              className={this.props.data.cssfile.nation_flag}
                              alt="nation flag"
                            />
                            <span
                              className={this.props.data.cssfile.nation_label}
                            >
                              India
                            </span>
                          </span>

                          <div
                            className={
                              this.props.data.cssfile.country_drop_container
                            }
                            style={{ display: "none" }}
                          >
                            <div
                              className={
                                this.props.data.cssfile.country_scroll_box
                              }
                            >
                              <div
                                className={
                                  this.props.data.cssfile.country_dtail_wrap
                                }
                              >
                                <ul>
                                  <li
                                    className={
                                      this.props.data.cssfile.country_name
                                    }
                                  >
                                    AED
                                  </li>
                                  <li
                                    className={
                                      this.props.data.cssfile.country_name
                                    }
                                  >
                                    USD
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Language currency dropdown */}
                    </li>

                    <li className={this.props.data.cssfile.dropdown}>
                      {/* <a href="#"> 
                        <img src="/images/profile-01.svg" className={this.props.data.cssfile.profile_icon} />
                        <span> My Account</span> 
                        <img src="/images/down_arrow.jpg" className={this.props.data.cssfile.drop_arrow} />
                      </a>  */}

                      {/* Login signup dropdown old */}
                      <div
                        className={this.props.data.cssfile.account_container}
                        style={{ display: "none" }}
                      >
                        <ul className={this.props.data.cssfile.drop_my_account}>
                          <li className={this.props.data.cssfile.login}>
                            <a href="#" title="Login">
                              <span>
                                {/* <img src="/images/profile-01.svg" className={this.props.cssfile.user_icon} /> */}
                                Login
                              </span>
                            </a>
                          </li>
                          <li className={this.props.data.cssfile.signup}>
                            <a href="#" title="Sign Up">
                              <span>
                                {/* <img src="/images/profile-01.svg" className={this.props.cssfile.user_icon} /> */}
                                Sign Up
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>

                      {/* Login signup dropdown */}
                    </li>
                  </ul>

                  <div
                    className={this.props.data.cssfile.afterlogin}
                    ref={this.setCalendarWrapperRef}
                  >
                    {this.state.userFirststname != undefined &&
                    this.state.userFirststname != null &&
                    this.state.userFirststname != "" ? (
                      <div className={this.props.data.cssfile.afterlogin}>
                        {" "}
                        <span className={this.props.data.cssfile.user_code}>
                          {this.state.userFirststname}
                        </span>
                        <span
                          style={{ fontSize: 12 }}
                        >{`${this.props?.data?.user?.first_name} ${this.props?.data?.user?.last_name}`}</span>
                        <span
                          onClick={() => {
                            this.onClickArrow();
                          }}
                        >
                          <img src="theme2/down_arrow.png" alt="My Account" />
                        </span>
                        <div
                          className={
                            this.state.isShowPopup
                              ? this.props.data.cssfile.show
                              : this.props.data.cssfile.profile_details_hide
                          }
                          onMouseLeave={() => {
                            this.onMouseLeave();
                          }}
                        >
                          {/* <div className={styles.profile_frst}> */}
                          <div className={this.props.data.cssfile.profile_frst}>
                            <span
                              onClick={() => {
                                this.onClickProfile();
                              }}
                            >
                              {" "}
                              <div
                                className={this.props.data.cssfile.menu_icon}
                              >
                                <img
                                  type="image"
                                  src="/images/profile-01.svg"
                                  alt="profile"
                                />
                              </div>
                              My Profile
                            </span>
                            <span onClick={this.myBookingClickHandler}>
                              {" "}
                              <div
                                className={this.props.data.cssfile.menu_icon}
                              >
                                <img
                                  type="image"
                                  src="/images/booking-01.svg"
                                  alt="mybooking"
                                />
                              </div>
                              My Booking
                            </span>
                            <span
                              onClick={() => {
                                this.onClickLogout();
                              }}
                            >
                              {" "}
                              <div
                                className={this.props.data.cssfile.menu_icon}
                              >
                                <img
                                  type="image"
                                  src="/images/logout-01.svg"
                                  alt="Logout"
                                />
                              </div>
                              Logout
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={this.props.data.cssfile.login_btn}
                        // onClick={this.props.data.onClickHeaderLoginB2C}
                        onClick={() => {
                          this.setState({ openInitialPopup: true });
                        }}
                      >
                        <a href="#" title="My Account">
                          <img
                            src="/images/up-01.svg"
                            className={this.props.data.cssfile.profile_icon}
                            alt="My Account"
                          />
                          <span> My Account</span>
                          <img
                            src="/theme2/down_arrow.png"
                            className={this.props.data.cssfile.drop_arrow}
                            alt="My Account"
                          />
                        </a>
                      </div>
                    )}

                    {this.state.openInitialPopup && (
                      <div
                        className="logindrop"
                        id="loginDrop"
                        style={{
                          display: "block",
                          width: 140,
                          borderRadius: 10,
                          top: 60,
                        }}
                      >
                        <div
                          className="ax-head-menu"
                          style={loginStyle}
                          onClick={() => {
                            commonFunction.redirectTo("/signin", { s: "0" });
                          }}
                        >
                          <img
                            style={{ filter: "brightness(100)" }}
                            type="image"
                            src="/images/loginew.svg"
                            alt="Sign In"
                          />
                          <span style={{ color: "white" }}>Sign In</span>
                        </div>
                        <div
                          className="ax-head-menu"
                          style={signupStyle}
                          onClick={() => {
                            commonFunction.redirectTo("/signin", { s: "1" });
                          }}
                        >
                          <img
                            type="image"
                            src="/images/registere.svg"
                            alt="Sign Up"
                          />
                          <span>Sign Up</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}

        {/* Header for HomePage ends */}

        {/* Header for other pages starts */}

        {window.location.pathname != "/" && (
          <header
            className={`${this.props.data.cssfile.new_header_wrap}  ${this.props.data.cssfile.header_sticky}`}
          >
            <div className={this.props.data.cssfile.header_section}>
              <div className="flex flex-row justify-between items-center container mx-auto px-4 xl:px-8">
                <h1>
                  <Link
                    title="Logo"
                    key={"logo"}
                    href={"/"}
                    // onclick="window.location.href='/'"
                    //onClick={() => { window.location.href = this.props.data.headermenu.supportlink }}
                    className=""
                  >
                    <img
                      className={this.props?.data?.cssfile?.header_logo}
                      src={`${
                        "/shared/api/media/" +
                        (UploadLogo?._currentValue?.isLogo
                          ? this.props?.data?.headermenu?.logo
                          : this.props?.data?.headermenu?.logo)
                      }`}
                      alt="Logo"
                    />
                  </Link>
                </h1>

                <h2>
                  <div>
                    <div className={this.props.data.cssfile.header_all_section}>
                      {!commonFunction.isValueEmpty(this.props) &&
                        !commonFunction.isValueEmpty(this.props.data) &&
                        !commonFunction.isValueEmpty(
                          this.props.data.headermenu
                        ) &&
                        !commonFunction.isValueEmpty(
                          this.props.data.headermenu.menu
                        ) &&
                        this.props.data.headermenu.menu.map(
                          (link, i) =>
                            link?.status.toLowerCase() === "active" && (
                              <div
                                key={"headerMenu" + i}
                                className={
                                  this.props.data.cssfile.header_sections
                                }
                              >
                                {link.url == "/holiday/" ||
                                link.url == "/holiday" ? (
                                  <Link
                                    title={link.text}
                                    key={link.text}
                                    onClick={() => {
                                      window.location.href = link.url;
                                    }}
                                    className=""
                                  >
                                    {link.text}
                                    <img
                                      src={`${
                                        "/shared/api/media/" +
                                        this.props?.data?.siteData?.site.id +
                                        "/" +
                                        link.icon
                                      }`}
                                      alt={link.text}
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    title={link.text}
                                    key={link.text}
                                    href={link.url}
                                    //onClick={() => { window.location.href = link.url }}
                                    className=""
                                  >
                                    {link.text}
                                    <img
                                      src={`${
                                        "/shared/api/media/" +
                                        this.props?.data?.siteData?.site.id +
                                        "/" +
                                        link.icon
                                      }`}
                                      alt={link.text}
                                    />
                                  </Link>
                                )}
                              </div>
                            )
                        )}
                    </div>
                  </div>
                </h2>

                <div className={this.props.data.cssfile.login_container}>
                  <ul className={this.props.data.cssfile.login_contact_dtail}>
                    <li className={this.props.data.cssfile.contact_number}>
                      <a href="tel:+9714 572 1555">
                        <img src="/images/cu-01.svg" alt="Call-Icon" />
                        <span>
                          {this.props?.data?.siteData?.site?.support_number}
                        </span>
                      </a>
                    </li>
                    {/* <li className={this.props.data.cssfile.contact_number}>
                      <a href=""> 
                      <img src="/images/wa-01.svg" alt="Whatsapp-Icon" /> 
                      <span>{this.props?.data?.siteData?.site?.support_number}</span> 
                      </a> 
                    </li> */}
                    <li className={this.props.data.cssfile.contact_title}>
                      <span>CONTACT US</span>
                    </li>
                  </ul>

                  <ul className={this.props.data.cssfile.navbar_right}>
                    <li className={this.props.data.cssfile.dropdown}>
                      <span className={this.props.data.cssfile.dropdown_span}>
                        {/* <img src="" className={this.props.data.cssfile.flag} alt=""/>  */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 60 30"
                          width="1200"
                          height="600"
                        >
                          <clipPath id="s">
                            <path d="M0,0 v30 h60 v-30 z" />
                          </clipPath>
                          <clipPath id="t">
                            <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
                          </clipPath>
                          <g clipPath="url(#s)">
                            <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
                            <path
                              d="M0,0 L60,30 M60,0 L0,30"
                              stroke="#fff"
                              strokeWidth="6"
                            />
                            <path
                              d="M0,0 L60,30 M60,0 L0,30"
                              clipPath="url(#t)"
                              stroke="#C8102E"
                              strokeWidth="4"
                            />
                            <path
                              d="M30,0 v30 M0,15 h60"
                              stroke="#fff"
                              strokeWidth="10"
                            />
                            <path
                              d="M30,0 v30 M0,15 h60"
                              stroke="#C8102E"
                              strokeWidth="6"
                            />
                          </g>
                        </svg>
                        <span>EN</span>
                        <span className={this.props.data.cssfile.divider_line}>
                          |
                        </span>
                        <span>AED</span>
                        {/* <img src="/theme2/down_arrow.png" className={this.props.data.cssfile.drop_arrow} alt=""/> */}
                      </span>

                      {/* Language currency dropdown */}
                      <div
                        className={this.props.data.cssfile.dropdown_menu_wrap}
                        style={{ display: "none" }}
                      >
                        <div className={this.props.data.cssfile.language_drop}>
                          <div
                            className={this.props.data.cssfile.language_label}
                          >
                            Language
                          </div>
                          <span
                            className={
                              this.props.data.cssfile.nation_title_wrap
                            }
                          >
                            <img
                              src=""
                              className={this.props.data.cssfile.nation_flag}
                              alt=""
                            />
                            <span
                              className={this.props.data.cssfile.nation_label}
                            >
                              India
                            </span>
                          </span>

                          <div
                            className={
                              this.props.data.cssfile.country_drop_container
                            }
                            style={{ display: "none" }}
                          >
                            <div
                              className={
                                this.props.data.cssfile.country_scroll_box
                              }
                            >
                              <div
                                className={
                                  this.props.data.cssfile.country_dtail_wrap
                                }
                              >
                                <ul>
                                  <li
                                    className={
                                      this.props.data.cssfile.country_name
                                    }
                                  >
                                    India
                                  </li>
                                  <li
                                    className={
                                      this.props.data.cssfile.country_name
                                    }
                                  >
                                    UAE
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={this.props.data.cssfile.language_drop}>
                          <div
                            className={this.props.data.cssfile.language_label}
                          >
                            Currency
                          </div>
                          <span
                            className={
                              this.props.data.cssfile.nation_title_wrap
                            }
                          >
                            <img
                              src=""
                              className={this.props.data.cssfile.nation_flag}
                              alt=""
                            />
                            <span
                              className={this.props.data.cssfile.nation_label}
                            >
                              India
                            </span>
                          </span>

                          <div
                            className={
                              this.props.data.cssfile.country_drop_container
                            }
                            style={{ display: "none" }}
                          >
                            <div
                              className={
                                this.props.data.cssfile.country_scroll_box
                              }
                            >
                              <div
                                className={
                                  this.props.data.cssfile.country_dtail_wrap
                                }
                              >
                                <ul>
                                  <li
                                    className={
                                      this.props.data.cssfile.country_name
                                    }
                                  >
                                    AED
                                  </li>
                                  <li
                                    className={
                                      this.props.data.cssfile.country_name
                                    }
                                  >
                                    USD
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Language currency dropdown */}
                    </li>
                  </ul>

                  <div
                    className={this.props.data.cssfile.afterlogin}
                    ref={this.setCalendarWrapperRef}
                  >
                    {this.state.userFirststname != undefined &&
                    this.state.userFirststname != null &&
                    this.state.userFirststname != "" ? (
                      <div className={this.props.data.cssfile.afterlogin}>
                        {" "}
                        <span className={this.props.data.cssfile.user_code}>
                          {this.state.userFirststname}
                        </span>
                        <span
                          style={{ fontSize: 12 }}
                        >{`${this.props?.data?.user?.first_name} ${this.props?.data?.user?.last_name}`}</span>
                        <span
                          onClick={() => {
                            this.onClickArrow();
                          }}
                        >
                          <img src="/images/down_arrow.jpg" alt="My Account" />
                        </span>
                        <div
                          className={
                            this.state.isShowPopup
                              ? this.props.data.cssfile.show
                              : this.props.data.cssfile.profile_details_hide
                          }
                          onMouseLeave={() => {
                            this.onMouseLeave();
                          }}
                        >
                          {/* <div className={styles.profile_frst}> */}
                          <div className={this.props.data.cssfile.profile_frst}>
                            <span
                              onClick={() => {
                                this.onClickProfile();
                              }}
                            >
                              {" "}
                              <div
                                className={this.props.data.cssfile.menu_icon}
                              >
                                <img
                                  type="image"
                                  src="/images/profile-01.svg"
                                  alt="profile"
                                />
                              </div>
                              My Profile
                            </span>
                            <span onClick={this.myBookingClickHandler}>
                              {" "}
                              <div
                                className={this.props.data.cssfile.menu_icon}
                              >
                                <img
                                  type="image"
                                  src="/images/booking-01.svg"
                                  alt="mybooking"
                                />
                              </div>
                              My Booking
                            </span>
                            <span
                              onClick={() => {
                                this.onClickLogout();
                              }}
                            >
                              {" "}
                              <div
                                className={this.props.data.cssfile.menu_icon}
                              >
                                <img
                                  type="image"
                                  src="/images/logout-01.svg"
                                  alt="Logout"
                                />
                              </div>
                              Logout
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={this.props.data.cssfile.login_btn}
                        // onClick={this.props.data.onClickHeaderLoginB2C}
                        onClick={() => {
                          this.setState({ openInitialPopup: true });
                        }}
                      >
                        <a href="#" title="My Account">
                          <img
                            src="/images/up-01.svg"
                            className={this.props.data.cssfile.profile_icon}
                            alt="profile"
                          />
                          <span> My Account</span>
                          <img
                            src="/theme2/down_arrow.png"
                            className={this.props.data.cssfile.drop_arrow}
                            alt="account"
                          />
                        </a>
                      </div>
                    )}

                    {this.state.openInitialPopup && (
                      <div
                        className="logindrop"
                        id="loginDrop"
                        style={{
                          display: "block",
                          height: 180,
                          width: 180,
                          borderRadius: 10,
                        }}
                      >
                        <div
                          className="ax-head-menu"
                          style={loginStyle}
                          onClick={() => {
                            commonFunction.redirectTo("/signin", { s: "0" });
                          }}
                        >
                          <img
                            style={{ color: "white" }}
                            type="image"
                            src="/images/loginew.svg"
                            alt="login"
                          />
                          <span style={{ color: "white" }}>Sign In</span>
                        </div>
                        <div
                          className="ax-head-menu"
                          style={signupStyle}
                          onClick={() => {
                            commonFunction.redirectTo("/signin", { s: "1" });
                          }}
                        >
                          <img
                            type="image"
                            src="/images/registere.svg"
                            alt="register"
                          />
                          <span>Sign Up</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}

        {/* Header for other pages ends */}
      </>
    );
  }
}

export default Header2;
