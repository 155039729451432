import { useEffect, useState } from "react";

const PwaInstall = (props) => {
  // console.log('pwanew', props.style);
  // console.log("==PwaInstall==", props, typeof window);

  if (typeof window !== "undefined") {
    let installPrompt = null;
    const installButton = document.querySelector("#installApp");
    //console.log("==PwaInstall window is enable==");
    window.addEventListener("beforeinstallprompt", (event) => {
      //console.log("==PwaInstall beforeinstallprompt===");
      event.preventDefault();
      installPrompt = event;
      installButton.removeAttribute("hidden");
    });

    if (installButton) {
      installButton.addEventListener("click", async () => {
        //console.log("==PwaInstall addEventListener click===");
        if (!installPrompt) {
          return;
        }
        const result = await installPrompt.prompt();
        //console.log(`Install prompt was result: ${result}`);
        //console.log(`Install prompt was: ${result.outcome}`);
        disableInAppInstallPrompt();
      });
    }

    window.addEventListener("appinstalled", () => {
      //console.log("==PwaInstall appinstalled===");
      disableInAppInstallPrompt();
    });

    function disableInAppInstallPrompt() {
      //console.log("==PwaInstall disableInAppInstallPrompt===");
      installPrompt = null;
      installButton.setAttribute("hidden", "");
    }
  }

  return (
    <button id="installApp" hidden className={props.style.download_btn}>
      <img
        src="theme5/plus-icon.svg"
        alt="plus"
        className={props.style.imgStyle}
      />
      Install Zenith Holidays app on your phone tap:
      <img
        src="theme5/download-downIcon.svg"
        alt="download"
        className={props.style.imgStyle}
      />
    </button>
  );
};

export default PwaInstall;
