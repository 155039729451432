import React, { Component, createRef } from "react";
import Router from "next/router";
import { logout } from "../../../lib/logout";
import commonFunction from "../../../lib/utils/common";
import assistedBooking from "../../../lib/assistedBooking";
import assistedNewToken from "../../../lib/assistedNewToken";
import AssistedBooking from "@/commonComponents/assistedBooking";
import Link from "next/link";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.menusWrapper = createRef();
    this.state = {
      hamburgerOpen: this.props.isopen,
      assistedBookingPopUp: false,
      assitedBookingData: {},
      btnClicked: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  handleClickOutside = (event) => {
    if (
      this.menusWrapper.current &&
      !this.menusWrapper.current.contains(event.target)
    ) {
      this.props.closeMenusHandler(false);
    }
  };

  toggleHamburger = () => {
    this.setState({ hamburgerOpen: !this.state.hamburgerOpen });
  };

  onClickRedirect = (data, index) => {
    // Router.push({
    //   pathname: '/myprofile',
    //   query: `menu=${data.href}`,
    if (data.href == "Logout") {
      this.props.onclickLogout();
    } else {
      this.props.clickedTab(index);
      window.location.href = `/myprofile?Linkindex=${index}`;
    }
    // })
  };

  onClickProfile = () => {
    // this.props.clickedTab(0)
    // commonFunction.redirectTo('/myprofile')
    window.location.href = `/myprofile?Linkindex=0`;
  };
  onClickLogout = () => {
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status === true ||
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            userFirststname: "",
            userType: "",
          });
          location.reload();
          commonFunction.redirectTo("/");
        } else {
        }
      })
      .catch((err) => {
        console.log("errrrrrrrrrrr", err);
      });
  };

  onClickHome = () => {
    this.props.clickedTab(0);
    commonFunction.redirectTo("/");
  };
  myBookingClickHandler = () => {
    this.props.clickedTab(1);
    window.location.href = `/myprofile?Linkindex=1`;
  };

  myQuotesClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=4`;
  };

  reportsClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=5`;
  };

  myClientsClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=9`;
  };

  myEnquiriesClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=1`;
  };

  importPNRClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=6`;
  };

  myWalletClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=7`;
  };

  settingClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=8`;
  };

  changePassClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=11`;
  };

  closeAssistedPopup = () => {
    this.props.closeMenusHandler(false);
  };

  handleAssistedBooking = async () => {
    this.setState({ btnClicked: true });
    if (this.props?.data?.user?.assit_bookerId === null) {
      const data = await assistedBooking(this.props.data.user.organisation.id);
      this.setState({ assitedBookingData: data });
      if (data) {
        this.setState(
          { assistedBookingPopUp: true, hamburgerOpen: false },
          () => {
            // this.setState({ btnClicked: false })
          }
        );
      } // Call the function passed as a prop with the data
    } else {
      const reqBody = {
        user_id: this.props?.data?.user?.assit_bookerId,
        assit_login: false,
        site_id: this.props?.data?.siteData?.data?.site_id || "",
      };
      const data = await assistedNewToken(reqBody);
      const { accessToken } = data;
      if (accessToken) {
        // this.props.router.reload();
        window.location.href = "/";
      }
    }
  };

  render() {
    console.log("=========================>", this.props);
    return (
      <div>
        {this.state.assistedBookingPopUp && (
          <AssistedBooking
            styles={this.props.data.assistedcss}
            data={this.state.assitedBookingData}
            setAssistedBokingPopup={() => {
              this.setState({ assistedBookingPopUp: false, btnClicked: false });
            }}
            theme={"theme4"}
            isMobile={true}
          />
        )}
        {this.props.isopen && (
          <>
            <div className={this.props.styles.nav_menu_backdrop}></div>
            <div
              ref={this.menusWrapper}
              className={this.props.styles.navigation_ineline}
            >
              {false ? (
                this.props.MenuList.length > 0 &&
                this.props.MenuList.map((element, index) => {
                  return (
                    <div
                      className={this.props.styles.mob_brd}
                      key={"menulist" + index}
                      onClick={() => {
                        this.onClickRedirect(element, index);
                      }}
                    >
                      <div className={this.props.styles.mobile_sidenav}>
                        <span
                          className={`${this.props.styles.my_settings_section_card_icon} ${this.props.styles.bui_u_margin8}`}
                        >
                          <img
                            src={element.imgInActiveSrc}
                            alt={"imapo"}
                            width="20"
                            height="20"
                          />{" "}
                        </span>
                        <a
                          id="myprofile"
                          role="button"
                          className={this.props.styles.menu_item}
                        >
                          {element.linkItem}
                        </a>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={this.props.styles.afterlogin}>
                  {/* <span className={this.props.styles.user_code}>{this.state.userFirststname}</span> */}
                  <div
                    className={`${this.props.styles.profile_User} ${this.props.styles.support_scnd}`}
                  >
                    <div class={this.props.styles.User_container}>
                      <div
                        onClick={this.onClickHome}
                        class={this.props.styles.user_icon}
                      >
                        <img src="/theme4/User_profile.png" alt="User Icon" />
                      </div>
                      <div class={this.props.styles.welcome_message}>
                        {this.props?.data?.user?.company_name &&
                          `Welcome, ` + this.props?.data?.user?.company_name}
                        {this.props?.data?.user?.first_name &&
                          `Welcome ` + this.props?.data?.user?.first_name}
                      </div>
                    </div>

                    <div class={this.props.styles.branch_container}>
                      <div
                        class={`${this.props.styles.branch_name} ${this.props.styles.branch_flx}`}
                      >
                        <span>
                          {" "}
                          {
                            this.props?.data?.user?.organisation?.type
                          } Name:{" "}
                        </span>
                        <span className={this.props.styles.banch_wd}>
                          {this.props?.branchName}
                        </span>
                      </div>
                      {this.props?.data?.user?.user_type != "CT" &&
                        this.props?.data?.user?.user_type != "TD" && this.props?.data?.user?.user_type != "AGENT" && 
                        this.props.data.user.is_assisted_booking_allowed && (
                          <div class={this.props.styles.assisted_booking}>
                            <button onClick={this.handleAssistedBooking}>
                              {this.props?.data?.user?.assit_bookerId === null
                                && "Assisted Booking"
                                }
                            </button>
                          </div>
                        )}
                      {this.props?.data?.user?.assit_bookerId && (
                        <div className={this.props.styles.assisted_booking}>
                          <button onClick={this.handleAssistedBooking}>
                            Back To Own Branch
                          </button>
                        </div>
                      )}
                    </div>
                    {/* <div class={this.props.styles.support_heading}>Support</div>
                  <a href="tel:+971 58 953 9988">
                    <span> <div className={this.props.styles.menu_icon}><img type="image" src="/images/call-01.svg" alt="call" /></div>+971 58 953 9988</span>
                  </a>
                  <a href="mailto:connect@elevatetrips.com"><span><div className={this.props.styles.menu_icon}><img type="image" src="/images/email-01.svg" alt="connect" /></div>connect@elevatetrips.com</span></a> */}
                  </div>
                  <div className={this.props.styles.profile_frst}>
                    <span>
                      <div className={this.props.styles.menu_icon}>
                        <img type="image" src="/theme4/inrMob.svg" alt="inr" />
                      </div>
                      INR
                    </span>
                    <span>
                      <div className={this.props.styles.menu_icon}>
                        <img
                          type="image"
                          src="/theme4/translate.svg"
                          alt="English"
                        />
                      </div>
                      English
                    </span>
                    <Link href={`/myprofile?Linkindex=0`}>
                      <span
                        onClick={() => {
                          this.props.clickedTab();
                        }}
                      >
                        <div className={this.props.styles.menu_icon}>
                          {" "}
                          <img
                            type="image"
                            src="/theme4/user-icon.svg"
                            alt="mybooking"
                          />
                        </div>{" "}
                        My Profile
                      </span>
                    </Link>
                    <Link href={`/myprofile?Linkindex=1`}>
                      <span
                        onClick={() => {
                          this.props.clickedTab(1);
                        }}
                      >
                        <div className={this.props.styles.menu_icon}>
                          {" "}
                          <img
                            type="image"
                            src="/theme4/my_booking-icon.svg"
                            alt="mybooking"
                          />
                        </div>{" "}
                        My Bookings
                      </span>
                    </Link>
                    {this.props?.data?.user?.type === "B2B" && (
                      <Link href={`/myprofile?Linkindex=15`}>
                        <span
                          onClick={() => {
                            this.props.clickedTab(15);
                          }}
                        >
                          <div className={this.props.styles.menu_icon}>
                            {" "}
                            <img
                              type="image"
                              src="/theme4/offline_booking-icon.svg"
                              alt="mybooking"
                            />
                          </div>{" "}
                          My Offline Bookings
                        </span>
                      </Link>
                    )}
                    {((this.props?.data?.user?.type === "B2E" &&
                      this.props?.data?.user?.user_type == "CT") ||
                      (this.state.userType === "B2E" &&
                        this.props?.data?.user?.corporate_detail
                          ?.is_corporate_traveller)) && (
                      <Link href={`/myprofile?Linkindex=3`}>
                        <span
                          onClick={() => {
                            this.props.clickedTab(3);
                          }}
                        >
                          <div className={this.props.styles.menu_icon}>
                            {" "}
                            <img
                              type="image"
                              src="/theme4/my_trip-icon.svg"
                              alt="mybooking"
                            />
                          </div>{" "}
                          My Trip
                        </span>
                      </Link>
                    )}
                    {this.props?.data?.user?.type === "B2E" &&
                      this.props?.data?.user?.corporate_detail?.is_approver && (
                        <Link href={`/myprofile?Linkindex=4`}>
                          <span
                            onClick={() => {
                              this.props.clickedTab(4);
                            }}
                          >
                            <div className={this.props.styles.menu_icon}>
                              {" "}
                              <img
                                type="image"
                                src="/theme4/review-trip-icon.svg"
                                alt="mybooking"
                              />
                            </div>{" "}
                            Review Trip
                          </span>
                        </Link>
                      )}
                    {this.props?.data?.user?.type == "B2B" && (
                      <Link href={`/myprofile?Linkindex=5`}>
                        <span
                          onClick={() => {
                            this.props.clickedTab(5);
                          }}
                        >
                          <div className={this.props.styles.menu_icon}>
                            {" "}
                            <img
                              type="image"
                              src="/theme4/client-icon.svg"
                              alt="mybooking"
                            />
                          </div>{" "}
                          My Clients
                        </span>
                      </Link>
                    )}

                    { this.props?.data?.user?.import_pnr && (
                      <Link href={`/myprofile?Linkindex=6`}>
                        <span
                          onClick={() => {
                            this.props.clickedTab(6);
                          }}
                        >
                          <div className={this.props.styles.menu_icon}>
                            {" "}
                            <img
                              type="image"
                              src="/theme4/pnr-icon.svg"
                              alt="mybooking"
                            />
                          </div>{" "}
                          Import PNR
                        </span>
                      </Link>
                    )}
                    {this.props?.data?.user?.type == "B2B" && (
                      <Link href={`/myprofile?Linkindex=8`}>
                        <span
                          onClick={() => {
                            this.props.clickedTab(8);
                          }}
                        >
                          <div className={this.props.styles.menu_icon}>
                            {" "}
                            <img
                              type="image"
                              src="/theme4/my-quotes-01.svg"
                              alt="mybooking"
                            />
                          </div>{" "}
                          My Quotes
                        </span>
                      </Link>
                    )}
                    {this.props?.data?.user?.type == "B2B" && (
                      <Link href={`/myprofile?Linkindex=9`}>
                        <span
                          onClick={() => {
                            this.props.clickedTab(9);
                          }}
                        >
                          <div className={this.props.styles.menu_icon}>
                            {" "}
                            <img
                              type="image"
                              src="/theme4/report-01.svg"
                              alt="mybooking"
                            />
                          </div>{" "}
                          Reports
                        </span>
                      </Link>
                    )}
                    {this.props?.data?.user?.user_type != "STAFF" &&
                      this.props?.data?.user?.user_type != "CT" &&
                      this.props?.data?.user?.user_type != "TD" && (
                        <Link href={`/myprofile?Linkindex=10`}>
                          <span
                            onClick={() => {
                              this.props.clickedTab(10);
                            }}
                          >
                            <div className={this.props.styles.menu_icon}>
                              {" "}
                              <img
                                type="image"
                                src="/theme4/wallet-01.svg"
                                alt="mybooking"
                              />
                            </div>{" "}
                            My wallet
                          </span>
                        </Link>
                      )}
                    {this.props?.data?.user?.user_type != "STAFF" &&
                      this.props?.data?.user?.user_type != "CT" &&
                      this.props?.data?.user?.user_type != "TD" &&
                      !(this.props?.data?.user?.userType == "B2E" && this.props?.data?.user?.user_type == 'ADMIN') && (
                        <Link href={`/myprofile?Linkindex=11`}>
                          <span
                            onClick={() => {
                              this.props.clickedTab(11);
                            }}
                          >
                            <div className={this.props.styles.menu_icon}>
                              {" "}
                              <img
                                type="image"
                                src="/theme4/setting-01.svg"
                                alt="mybooking"
                              />
                            </div>{" "}
                            Settings
                          </span>
                        </Link>
                      )}
                    {this.props?.data?.user?.type == "B2B" && (
                      <Link href={`/myprofile?Linkindex=14`}>
                        <span
                          onClick={() => {
                            this.props.clickedTab(14);
                          }}
                        >
                          <div className={this.props.styles.menu_icon}>
                            {" "}
                            <img
                              type="image"
                              src="/theme4/my-quotes-01.svg"
                              alt="mybooking"
                            />
                          </div>{" "}
                          Offline query
                        </span>
                      </Link>
                    )}
                    <Link href={`/myprofile?Linkindex=12`}>
                      <span
                        onClick={() => {
                          this.props.clickedTab(12);
                        }}
                      >
                        <div className={this.props.styles.menu_icon}>
                          {" "}
                          <img
                            type="image"
                            src="/theme4/changePwd-g.svg"
                            alt="mybooking"
                          />
                        </div>{" "}
                        Change Password
                      </span>
                    </Link>
                    <span onClick={this.onClickLogout}>
                      <div className={this.props.styles.menu_icon}>
                        <img
                          type="image"
                          src="/theme4/logout-Mob.svg"
                          alt="Logout"
                        />
                      </div>
                      Logout
                    </span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Menu;
